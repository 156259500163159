import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom'
import Layout from './components/Layout'
import Appointement from './pages/Appointement'
import Booked from './pages/Booked'
import Calendar from './pages/Calendar'
import Perimeters from './pages/Perimeters'
import Personal from './pages/Personal'
import Solutions from './pages/Solutions'
import Subscribe from './pages/Subscribe'
import Subscription from './pages/Subscription'
import SuccessMessage from './pages/SuccessMessage'

function App() {
	// TODO : review paths
	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route path="/" element={<Layout />}>
				<Route index element={<Subscribe />} />
				<Route path="perimeters" element={<Perimeters />} />
				<Route path="solutions" element={<Solutions />} />
				<Route path="appointement" element={<Appointement />} />
				<Route path="appointement/calendar" element={<Calendar />} />
				<Route path="appointement/booked" element={<Booked />} />
				<Route path="subscription" element={<Subscription />} />
				<Route path="subscription/personal" element={<Personal />} />
				<Route path="success-message" element={<SuccessMessage />} />
				<Route path="*" element={<Navigate to="/" replace={true} />} />
			</Route>
		)
	)

    return (
		<main className="app">
			<RouterProvider router={router} />
        </main>
    );
}

export default App;
