import LanguageSelect from '../components/LanguageSelect'
import { ReactComponent as LogoLockSelf } from '../assets/images/logo.svg'
import { useLocation } from 'react-router-dom'

export default function Board({ classes = '', children }) {
	const location = useLocation()

    return (
        <div className={`board-wrapper ${classes}`}>
            <LogoLockSelf className="logo" />
            <div className="board-container">
                {children}
            </div>

			{!location.pathname.includes('success-message') && <LanguageSelect />}
        </div>
    )
}
